/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, InfoItem, Headline, CareInstructions, CareItem, Obl} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Sweat protects the body from overheating."), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Excessive sweating, also known as hyperhidrosis"), ", is a result of overactivity of the sweat glands."), React.createElement(_components.p, null, "Excessive sweating can be triggered by emotions, medical conditions, and medications."), React.createElement(_components.p, null, "This can be idiopathic or secondary to an underlying medical condition such as"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "a metabolic disorder"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "infection"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "or medication use"), "\n"), "\n")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Secondary"), " hyperhidrosis presents with generalized or nocturnal sweating."), React.createElement(_components.p, null, "For ", React.createElement(_components.strong, null, "primary"), " hyperhidrosis, or excessive sweating without an underlying cause, there are several common treatment options...")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Antiperspirants"), React.createElement(_components.p, null, "Antiperspirants with metallic salts, such as aluminum, can help."), React.createElement(_components.p, null, "These work by mixing with the sweat and forming a substance that temporarily blocks the sweat gland."), React.createElement(_components.p, null, "Over time, this substance washes away and the antiperspirant must be reapplied.")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Iontophoresis"), React.createElement(_components.p, null, "Iontophoresis uses a mild electrical current through water to reduce sweat production."), React.createElement(_components.p, null, "Iontophoresis affects the electrical gradient formed by sympathetic nerve activity in the sweat gland.")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Botox"), React.createElement(_components.p, null, "Botox injections can disable sympathetic nerves in the skin and stop sweat production for several months; however, the ", React.createElement(_components.strong, null, "injections can be painful and treatment requires multiple injections"), ".")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure05",
    image: "figure05",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure05",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Lasers"), React.createElement(_components.p, null, "Lasers use heat to permanently destroy the sweat gland in the target area.")), React.createElement(Pin, {
    id: "figure05",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(_components.p, null, "Discuss excessive sweating with your primary care doctor or dermatologist to determine if there is an underlying cause."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "The most common affected areas of idiopathic hyperhidrosis are the palms, feet, or underarms."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Treatment options include topicals such as ", React.createElement(Obl, {
    to: "https://amzn.to/3XmpREg",
    title: "Amazon Affiliate Link"
  }, "aluminum chloride"), ", or ", React.createElement(Obl, {
    to: "https://amzn.to/3AzEWJ0",
    title: "Amazon Affiliate Link"
  }, "glycopyrrolate"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Oral medications such as ", React.createElement(Obl, {
    to: "https://amzn.to/3UZmjpZ",
    title: "Amazon Affiliate Link"
  }, "glycopyrrolate"), ", ", React.createElement(Obl, {
    to: "https://amzn.to/3XpTy7i",
    title: "Amazon Affiliate Link"
  }, "oxybutynin"), " and ", React.createElement(Obl, {
    to: "https://amzn.to/3i4SDJu",
    title: "Amazon Affiliate Link"
  }, "clonidine"), " can also be utilized."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(Obl, {
    to: "https://amzn.to/3VfHGCX",
    title: "Amazon Affiliate Link"
  }, "Botulinum toxin"), " can be administered intradermally and is used to treat axillary, palmar, plantar and scalp ", React.createElement(_components.a, {
    href: "/excessive-sweating-hyperhidrosis"
  }, "hyperhidrosis"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Tap water ", React.createElement(Obl, {
    to: "https://amzn.to/3XqgNhu",
    title: "Amazon Affiliate Link"
  }, "iontophoresis"), ", microwave ", React.createElement(Obl, {
    to: "https://amzn.to/3Aw1OJ1",
    title: "Amazon Affiliate Link"
  }, "thermolysis"), " and local surgery can also be utilized."), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
